

































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { api } from '@/api';
import { readToken } from '@/store/main/getters';
import { commitAddNotification } from '@/store/main/mutations';
import { pairwiseAppUrl } from '@/env';

@Component
export default class Pairwise extends Vue {
    public pairwise_id: null | number = null;
    public title = '';
    public subtitle = '';
    public question = '';
    public state = '';
    public number_of_votes_per_participant = 1;
    public newItemDialog = false;
    public newParticipantDialog = false;
    public simulateDialog = false;
    public participantUploadIsSelecting = false;
    public participantSelectedFile: File | null = null;
    public newParticipantEmail = '';
    public reducementTypes = [
        "merge_sort",
        "close_calls_only"
    ]
    public selectedReducementType = "close_calls_only";
    public simulatorCloseCallThreshold = 5;
    public items = {};
    public activeSession = false;
    public session_progress = 0;
    public summaryIsFresh = false;
    public resultsFilters = {
        close_calls: false,
        ai: false,
        human: false
    };

    public resetResultsFilters() {
        this.resultsFilters = {
            close_calls: false,
            ai: false,
            human: false
        }
    }

    @Watch('resultsFilters') onResultsFiltersChanged() {
        console.log("resultsFilters", this.resultsFilters);
        console.log(this.comparisonsTableItemsFiltered);
        this.comparisonsTableItemsFiltered = this.comparisonsTableItems.filter((item) => {
            // if all filters are off, show all
            if (!this.resultsFilters.close_calls && !this.resultsFilters.ai && !this.resultsFilters.human) {
                return true;
            }
            if (this.resultsFilters.close_calls && item["close_call"]) {
                return true;
            }
            // if (this.resultsFilters.ai && !item.is_human) {
            //     return true;
            // }
            // if (this.resultsFilters.human && item.is_human) {
            //     return true;
            // }
            return false;
        })
    }

    public setFilter(filter) {
        // set whole object to trigger watcher
        this.resultsFilters = {
            ...this.resultsFilters,
            [filter]: !this.resultsFilters[filter]
        }
    }



    public mounted() {
        if (this.$route.params.id) {
            this.pairwise_id = parseInt(this.$route.params.id);
            this.loadSurvey();
        }
    }

    public async loadSurvey(try_count=0, last_progress=0) {
        if (this.pairwise_id == null) {
            return;
        }
        const response = await api.getPairwiseSurvey(readToken(this.$store), this.pairwise_id);
        if (response.status === 200) {
            this.title = response.data.title;
            this.subtitle = response.data.subtitle;
            this.question = response.data.question;
            this.number_of_votes_per_participant = response.data.number_of_votes_per_participant;
            this.tablePairwiseItems = response.data.items;
            this.items = {}
            response.data.items.forEach((item) => {
                this.items[item.uuid.replace(/-/g, '')] = item;
            });
            this.participants = response.data.participants;
            this.state = response.data.state;
            this.resultsTableItems = response.data.results;
            this.comparisonsTableItems = response.data.result_comparisons;
            this.comparisonsTableItemsFiltered = response.data.result_comparisons;
            this.session_progress = response.data.session_progress;
            this.activeSession = response.data.active_session;
            if (this.activeSession) {
                setTimeout(() => {
                    this.loadSurvey(try_count + 1, this.session_progress);
                }, 2000);
            } else {
                if (last_progress != 0) {
                    this.summaryIsFresh = true;
                }
            }
        }
    }

    public pairwiseItemsTableHeaders = [
        // {
        //     text: 'ID',
        //     align: 'start',
        //     value: 'id',
        // },
        {
            text: 'Description',
            align: 'start',
            value: 'description',
        },
        // { text: 'Rank', value: 'estimate' },
        { text: 'Actions', value: 'actions', sortable: false },
    ];
    public resultsTableHeaders = [
        {
            text: 'Description',
            align: 'start',
            value: 'description',
        },
        { text: 'Votes', value: 'votes' },
        { text: 'BT Rank', value: 'score' },
    ];
    public resultsTableItems = [];
    public comparisonsTableHeaders = [
        {
            text: 'Labels',
            value: 'labels',
            sortable: false,
            width: '20%',
        },
        {
            text: 'Comparison',
            value: 'comparison',
            sortable: false,
            width: '80%',
        }
    ];
    public comparisonsTableItems = [];
    public comparisonsTableItemsFiltered = [];
    public tablePairwiseItems: {
        id: number;
        uuid: string;
        description: string;
        rank: number;
    }[] = [];
    public resultsSubTableHeaders = [
        {
            text: 'Description',
            align: 'start',
            value: 'description',
        },
        {
            text: 'Score',
            value: 'score_pair'
        },
        {
            text: 'Score Parent',
            value: 'score_self'
        },
    ]
    resultsItemComparisons(comparisons) {
        return Object.keys(comparisons).map((key) => {
            return {
                uuid: key,
                description: comparisons[key].description,
                score_pair: comparisons[key].score_pair,
                score_self: comparisons[key].score_self
            }
        })
    }
    public participantsTableHeaders = [
        {
            text: 'UUID',
            value: 'uuid',
        },
        {
            text: 'Email',
            value: 'email',
        },
        { text: 'Created', value: 'created' },
        { text: 'Actions', value: 'actions', sortable: false },
    ];
    public participants: {
        uuid: string;
        created: number;
        email: string;
    }[] = [];
    public pairwiseItemUploadIsSelecting = false;
    public paireiseItemSelectedFile: File | null = null;
    public newPairwiseItemDescription = '';


    public pairwiseItemUploadClick() {
        this.pairwiseItemUploadIsSelecting = true;
        window.addEventListener('focus', () => {
            this.pairwiseItemUploadIsSelecting = false;
        }, { once: true });

        const uploader = this.$refs.uploader as HTMLInputElement;
        uploader.click();
    }
    public async pairwiseItemOnFileChanged(e) {
        this.paireiseItemSelectedFile = e.target.files[0];
        if (!this.paireiseItemSelectedFile) {
            return;
        }
        const response = await api.importPairwiseItems(
            readToken(this.$store),
            this.pairwise_id as number,
            this.paireiseItemSelectedFile
        );
        if (response.status === 200) {
            this.tablePairwiseItems = response.data;
            commitAddNotification(this.$store, {
                content: 'Items imported.',
                color: 'success'
            });
        }
        e.target.value = null;
    }

    public async savePairwise() {
        if (this.pairwise_id) {
            const response = await api.updatePairwiseSurvey(readToken(this.$store),
                this.pairwise_id,
                {
                    title: this.title,
                    subtitle: this.subtitle,
                    question: this.question,
                    number_of_votes_per_participant: this.number_of_votes_per_participant
                }
            );
            if (response.status === 200) {
                commitAddNotification(this.$store, {
                    content: 'Updated successfully.',
                    color: 'success'
                });
            }
        } else {
            const response = await api.createPairwiseSurvey(readToken(this.$store),
                {
                    title: this.title,
                    subtitle: this.subtitle,
                    question: this.question,
                    number_of_votes_per_participant: this.number_of_votes_per_participant
                }
            );
            if (response.status === 200) {
                this.pairwise_id = response.data.id;
                commitAddNotification(this.$store, {
                    content: 'Saved successfully.',
                    color: 'success'
                });
                this.$router.push(`/main/user/pairwise/${response.data.id}`);
            }
        }
    }

    public async launchPairwise() {
        if (!this.pairwise_id) return;
        const response = await api.updatePairwiseSurvey(readToken(this.$store),
            this.pairwise_id,
            {
                state: "active"
            }
        );
        if (response.status === 200) {
            commitAddNotification(this.$store, {
                content: 'Launched successfully.',
                color: 'success'
            });
            this.state = "active";
            setTimeout(() => {
                this.loadSurvey();
            }, 2000);
        }
    }

    public async rerunCloseCalls() {
        if (!this.pairwise_id) return;
        const response = await api.pairwiseRerunCloseCalls(readToken(this.$store), this.pairwise_id, 10, false);
        if (response.status === 200) {
            commitAddNotification(this.$store, {
                content: 'Rerunning close calls.',
                color: 'success'
            });
            this.loadSurvey();
        }
    }

    public async runSimulator() {
        if (!this.pairwise_id) return;
        try {
            const response = await api.pairwiseRunSimulator(readToken(this.$store), this.pairwise_id, this.selectedReducementType, this.simulatorCloseCallThreshold);
            if (response.status === 200) {
                commitAddNotification(this.$store, {
                    content: 'Running agents.',
                    color: 'success'
                });
                this.simulateDialog = false;
                this.loadSurvey();
            }
        } catch {

        }

    }

    get pairwiseItemImportButtonText() {
        return this.paireiseItemSelectedFile ? this.paireiseItemSelectedFile.name : 'Import File';
    }

    public async saveNewPairwiseItem() {
        const response = await api.createPairwiseItem(readToken(this.$store), this.pairwise_id as number, this.newPairwiseItemDescription);
        if (response.status === 200) {
            this.newItemDialog = false;
            this.tablePairwiseItems.push(response.data);
            this.newPairwiseItemDescription = "";
            commitAddNotification(this.$store, {
                content: 'Item added.',
                color: 'success'
            });
        }
    }
    public editPairwiseItem(item) {

    }

    public async deletePairwiseItem(item) {
        const response = await api.deletePairwiseItem(readToken(this.$store), this.pairwise_id as number, item.id);
        if (response.status === 200) {
            this.tablePairwiseItems = this.tablePairwiseItems.filter(pairwiseItem => pairwiseItem.id !== item.id);
            commitAddNotification(this.$store, {
                content: 'Item deleted.',
                color: 'success'
            });
        } else {
            commitAddNotification(this.$store, {
                content: 'Failed to delete item.',
                color: 'error'
            });
        }
    }

    public participantUploadClick() {
        this.participantUploadIsSelecting = true;
        window.addEventListener('focus', () => {
            this.participantUploadIsSelecting = false;
        }, { once: true });

        const uploader = this.$refs.participantUploader as HTMLInputElement;
        uploader.click();
    }

    public async participantOnFileChanged(e) {
        this.participantSelectedFile = e.target.files[0];
        if (!this.participantSelectedFile) {
            return;
        }
        const response = await api.importPaiwiseSurveyParticipants(
            readToken(this.$store),
            this.pairwise_id as number,
            this.participantSelectedFile
        );
        if (response.status === 200) {
            this.participants = response.data;
            commitAddNotification(this.$store, {
                content: 'Participants imported.',
                color: 'success'
            });
        }
        e.target.value = null;
    }

    get participantImportButtonText() {
        return this.participantSelectedFile ? this.participantSelectedFile.name : 'Import File';
    }

    public async saveNewParticipant() {
        const response = await api.createPairwiseSurveyParticipant(readToken(this.$store), this.pairwise_id as number, this.newParticipantEmail);
        if (response.status === 200) {
            this.newParticipantDialog = false;
            this.participants.push(response.data);
            this.newParticipantEmail = "";
            commitAddNotification(this.$store, {
                content: 'Participant added.',
                color: 'success'
            });
        }
    }

    public async deleteParticipant(item) {
        const response = await api.deletePairwiseSurveyParticipant(readToken(this.$store), this.pairwise_id as number, item.uuid);
        if (response.status === 200) {
            this.participants = this.participants.filter(participant => participant.uuid !== item.uuid);
            commitAddNotification(this.$store, {
                content: 'Participant deleted.',
                color: 'success'
            });
        } else {
            commitAddNotification(this.$store, {
                content: 'Failed to delete participant.',
                color: 'error'
            });
        }
    }

    public async deleteParticipantConfirm() {
        // Implement delete confirmation
    }

    public copyToClipboard(item) {
        console.log("url", pairwiseAppUrl);
        console.log("item", item);
        const el = document.createElement('textarea');
        el.value = `${pairwiseAppUrl}/survey/${item.uuid}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}
